<template>
    <div class="page-table musteriTanim  mainDiv mb-30" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            Evrak Güncelle
        </div>
        
        <el-row :gutter="10" v-loading="loading || evrakLoading || kurumLoading || cakismaLoading"
            :element-loading-text='loading ? "Evrak Detay Bilgileri Getiriliyor. Lütfen Bekleyiniz." : evrakLoading ? "Evrak Bilgileri Güncelleniyor. Lütfen Bekleyiniz." : kurumLoading ? "Cari Bilgileri Getiriliyor. Lütfen Bekleyiniz." : "Tarih Kontrol Ediliyor. Lütfen Bekleyiniz."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="evrakForm" :rules="rulesEvrak" ref="evrakForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">                        
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10" >
                            <el-row>

                                <el-col :lg="6" :md="6" :sm="6" :xs="6" class="col-p">
                                    <el-form-item label="Evrak No" prop="kayitKodu">:
                                        <el-input size="small"
                                            v-model="evrakForm.evrakNo"
                                            :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </el-col> 

                                <el-col :lg="6" :md="6" :sm="6" :xs="6" class="col-p">
                                    <el-form-item label="Evrak Durumu" prop="evrakDurumu">:
                                        <el-input size="small"
                                            v-model="evrakForm.evrakDurumAdi"
                                            :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </el-col> 

                                <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                    <el-form-item label="Cari" prop="kurumID">:
                                        <el-select style="width: 100%" size="small"  v-model="evrakForm.kurumID" placeholder="Cari Seçiniz">
                                            <el-option
                                                v-for="item in kurumListe" 
                                                :key="item.kurumID"
                                                :label="item.kurumAdi"
                                                :value="item.kurumID">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>   
                            </el-row>
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Evrak Konu" prop="evrakKonu">:
                                        <el-input size="small"
                                            v-model="evrakForm.evrakKonu"
                                            :placeholder="'Evrak Konu Giriniz'"></el-input>
                                    </el-form-item>
                                </el-col> 

                                <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                    <el-form-item label="Tarih" prop="evrakTarihi">:
                                        <el-tooltip :disabled="true" content='Evrak Tarihi' :open-delay="250" placement="bottom">
                                            <el-date-picker 
                                                v-model="evrakForm.evrakTarihi"
                                                @change="cakismaKontrol(evrakForm.evrakTarihi)"
                                                size="small" class="select-wide" 
                                                type="date"
                                                :placeholder='"Tarih Seçiniz"'
                                                format="dd.MM.yyyy"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="{ firstDayOfWeek: 1 }"
                                                :disabled="true">
                                            </el-date-picker>
                                        </el-tooltip>
                                    </el-form-item>
                                </el-col>   
                                </el-row>   
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="evrakForm.evrakAdi != '' & !checked">
                                        <el-form-item prop="evrak">
                                            <el-upload 
                                            id="evrakEkle"
                                            action=""
                                            :auto-upload="false"
                                            :limit="1"
                                            :file-list="evrakListMain"
                                            :on-change="evrakUpload"
                                            :on-remove="evrakRemove"
                                            list-type="belge-card"
                                            accept="">
                                            <el-button size="mini" type="primary" icon="el-icon-upload">Dosya Seç</el-button> 
                                            <el-button size="mini" type="danger" icon="el-icon-close" @click="checked = true" circle style="background-color:rgb(172, 8, 8); border-color:rgb(172, 8, 8);"></el-button> 
                                            <div class="el-upload__tip" slot="tip" style="font-weight: normal !important">jpg/png/doc/xls/pdf formatında belge yükleyebilirsiniz.</div>
                                        </el-upload>
                                        </el-form-item>
                                    </el-col>   
                                    <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p" v-if="evrakForm.evrakAdi == '' & checked">
                                        <el-form-item prop="evrak">
                                            <el-checkbox v-model="checked">Dosyayı sonra ekleyeceğim.</el-checkbox>
                                        </el-form-item>
                                    </el-col>  
                                    
                                    <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                        <el-form-item style="color: rgb(172, 8, 8)">
                                            {{ cakisma }}
                                        </el-form-item>
                                    </el-col> 
                                </el-row>
                                

                                                                             
                        </div>                   

                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="8" :title='$t("src.views.apps.genel.durum")'>
                            <el-row> 
                                <!--
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select
                                            size="small"
                                            v-model="evrakForm.durum"
                                            :placeholder='$t("src.views.apps.messages.addBultenTitle")'
                                            style="width: 100%">
                                            <el-option value="0" :label='$t("src.views.apps.durum.kurum.pasif")'></el-option>
                                            <el-option value="1" :label='$t("src.views.apps.durum.kurum.aktif")'></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>-->
                                <el-button size="mini" v-on:click="evrakGuncelle('evrakForm',evrakForm.durum)" class="onayBtn" type="primary">
                                    <label>Güncelle</label>
                                </el-button>
                                &nbsp;
                                <el-popover placement="bottom" width="310" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('evrakForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";

    import kurumService from '../../../WSProvider/KurumService'
    import dosyaService from '../../../WSProvider/DosyaService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import JQuery from 'jquery';

    let $ = JQuery;

    var moment = require('moment');

    export default {
        name: "EvrakGuncelle",
        components: {
            draggable
        },

        computed: {
            storeData(){
                return this.$store.getters.getEvrakUpdateData
            }
        },
        watch: {
            storeData(val){
                this.evrakForm.evrakID = this.$store.getters.getEvrakUpdateData
                this.getEvrakDetay();
            }
        },
        
        mounted() {            
            this.evrakForm.evrakID = this.$store.getters.getEvrakUpdateData
            this.getEvrakDetay();
           // this.getEvrakDurumList();
        },
        
        data() {
            return {
                radio: ['1'],
                belgePath: dosyaService.dosyaPath,
                kurumListe: [],
                evrakDurumListe: [],
                kurumLoading: false,
                evrakLoading: false,
                checked: true,
                cakisma: "",
                cakismaLoading: false,
                selectedOption: ['Gelen', 'Giden'],
                loading : false,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr',
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
                visibleSil: false,
                disabled: false,
                evrakListMain: [],
                evrakForm: {
                    evrak: [],
                    evrakID: '',
                    evrakNo: '',
                    dosyaID: '',
                    evrakAdi: '',
                    evrakDurumAdi: '',
                    evrakKonu: '',
                    evrakTarihi: '',
                    kurumID: "",
                    kurumAdi: "",
                    gelen: "",
                    giden: "",
                    kurumDurumu: '',         
                    durum: '1',
                    evrakDurumID:"",
                    evrakDurumu: [
                        {evrakDurumAdi: "Gelen", evrakDurumID: "1"},
                        {evrakDurumAdi: "Giden", evrakDurumID: "2"}
                    ],
                },
                rulesEvrak: {  /*                  
                    evrakKonu: [{
                        required: true,
                        message: "Lütfen Evrak Konu Giriniz",
                        trigger: 'blur'
                    }]           */ 
                },
            }
        },
        methods: {

            cakismaKontrol(tarih){
                this.cakismaLoading = true;
                dosyaService.tarihKontrol(tarih).then((response) => {
                    localStorage.setItem("userDataBGSurec", response.token)
                    if (response.status == 200) {
                        this.cakisma = response.msg;
                    }
                    this.cakismaLoading = false;
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.kontrol = false; 
                });
            },

            getEvrakDetay(){
                try{
                    this.loading = true;
                    dosyaService.dosyaDetay(this.evrakForm.evrakID).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token);

                            this.evrakForm.evrakDurumID = response.data.dosyaDurumu 
                            this.evrakForm.evrakDurumAdi = response.data.dosyaDurumu == "I" ? "Gelen" : "Giden"                      
                            if(response.data.kurumID == 0 ) {
                                this.evrakForm.kurumID = "" 
                                this.evrakForm.kurumAdi = ""
                                this.getKurumList();
                            } else{
                                this.evrakForm.kurumID = response.data.kurumID
                                this.evrakForm.kurumAdi = response.data.kurumAdi
                                this.getKurumList();
                            }
                            this.evrakForm.evrakKonu = response.data.konu
                            this.evrakForm.evrakTarihi = response.data.islemTarihi    
                            this.evrakForm.evrakAdi = response.data.dosyaAdi 
                            this.evrakForm.evrakNo = response.data.kayitKodu 

                            if (response.data.dosyaAdi) {

                                
                                const dosyaAdi = response.data.dosyaAdi;
                                const dosyaURL = this.belgePath + response.data.dosyaAdi;

                                axios.get(dosyaURL, { responseType: 'blob' }) // Dosya içeriğini blob olarak alır
                                .then(fileResponse => { 
                                    // Blob verisini bir File nesnesine dönüştürün
                                    const dosya = new File([fileResponse.data], dosyaAdi);

                                    // Dosyayı evrakForm.evrak'e ekleyin
                                    this.evrakForm.evrak.push(dosya);
                                    console.log(this.evrakForm.evrak);
                                })
                                .catch(fileError => {
                                    console.error('Dosya alınamadı:', fileError);
                                });


                            }



                            this.evrakForm.durum = response.data.durum                            
                            this.kurumData = response.data
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            getKurumList() {
                try{
                    this.kurumLoading = true;
                    kurumService.kurumListele(this.evrakForm.evrakDurumID == "I" ? 1 : 2, "", "").then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            this.kurumListe = response.data;
                        }
                        this.kurumLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kurumListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.kurumLoading = false;
                    })
                }catch(e){
                    this.kurumLoading = false;
                }
            },

            getEvrakDurumList() {
                try{
                    dosyaService.evrakDurum().then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            this.evrakDurumListe = response.evrakDurum;
                        }
                        this.kurumLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kurumListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.kurumLoading = false;
                    })
                }catch(e){
                    this.kurumLoading = false;
                }
            },

            evrakGuncelle(formName) {
                this.$refs[formName].validate((valid, errors) => {
                    if (valid) {
                        this.$confirm("Evrakı Güncellemek İstediğinize Emin Misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            this.evrakLoading = true;
                            console.log(this.evrakForm.evrak)
                            console.log(this.evrakForm.evrak[0])
                            dosyaService.dosyaGuncelle(this.evrakForm.evrakID, this.evrakForm.kurumID, this.evrakForm.evrakKonu, this.evrakForm.evrak[0] == undefined ? "" : this.evrakForm.evrak[0]).then((response) => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)      
                                    notification.Status("success", this, response.msg)                                    
                                    EventBus.$emit("evrakList", true)  
                                    functions.sayfaKapat("evrakguncelle", this);                                  
                                    this.resetForm(formName)
                                }
                                this.evrakLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.evrakLoading = false;
                            })
                        }).catch((e) => {})
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.evrakLoading = false
                        }
                    }
                })
            },

            formatEvrakNo(row) {
                const parts = row.kayitKodu.split("-");
                return parts[0]; // Sadece ilk bölümü (I8) döndür
            },


            resetForm(formName) {
                if (this.$refs[formName]) {
                    this.evrakForm.evrak = [];
                    this.evrakForm.evrakKonu = '';
                    this.evrakForm.evrakAdi = ''
                    this.$refs[formName].clearValidate();
                }
                this.visibleSil = false;
            },


            evrakRemove() {
                $('#evrakEkle .el-upload--belge-card').show();
                this.evrakListMain = [];
                this.evrakForm.evrak = [];
                this.evrakForm.evrakAdi = '';
            },

            evrakUpload(file, fileList) {
                this.evrakListMain = [];
                this.evrakForm.evrak = [];
                this.evrakForm.evrakAdi = '';

                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.evrakListMain.push(item);

                    file.raw["tmp_name"] = file.url;

                    this.evrakForm.evrak.push(file.raw);

                    fileList.pop();

                    //$('#belgeekle-resim .el-upload--picture-card').hide();
                
            },

            vazgec(formName) {
                this.$refs[formName].resetFields();
                this.btnVisible = true;
                this.evrakForm.evrakDurumID = "";
                this.evrakListMain = [];
                this.evrakForm.evrak = []
                $('#evrakEkle .el-upload--belge-card').show();
                this.guncelle = false;
            },

        }
    }

</script>

<style>
.el-input.is-disabled .el-input__inner {
    background-color: #f3f3f3ab;
    border-color: #048484;
    color: #525353;
    cursor: not-allowed;
}
</style>